import { useEffect, useMemo, useReducer, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import {
  Card,
  Table,
  Pagination,
  Select,
  Input,
  Badge,
  Avatar,
  Notification,
  toast,
  Tag,
  Modal,
  Button,
} from "components/ui";
import dayjs from "dayjs";


const { Tr, Th, Td, THead, TBody, Sorter } = Table;

const ScanHistory = ({ data }) => {
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");


  const columns = useMemo(
    () => [
      // {
      //   header: "Scan Date",
      //   accessorKey: "createdAt",
      //   cell: (info) => info.getValue()?.split("T")[0] || "-",
      // },
      {
        header: "Scan Date",
        accessorKey: "createdAt",
        // cell: (info) => dayjs.unix(info.getValue()).format("YYYY/MM/DD HH:mm:ss") || "-",
        cell: (props) => {
          const { createdAt } = props.row.original;
          return (
            <div className="flex items-center gap-2">
                {dayjs(createdAt).format("YYYY/MM/DD HH:mm:ss")}
            </div>
          );
        },
      },
      {
        header: "Scan Mode",
        accessorKey: "scanMode",
      },
      {
        header: "Scan By",
        accessorKey: "scannedByUserName",
      },
      {
        header: "Voltage",
        accessorKey: "voltage",
      },
      {
        header: "Alt Voltage",
        accessorKey: "alternatorVoltage",
      },
      {
        header: "CCA",
        accessorKey: "cca",
      },
      {
        header: "AGM",
        accessorKey: "agm",
      },
      {
        header: " Cranking %",
        accessorKey: "crankingHealth",
        cell: (info) => info.getValue() === null ?  "" : `${info.getValue()} %`,  
      },
      {
        header: "Scan Location",
        accessorKey: "ipLocation",
        cell: (props) => {
          const { ipLocation } = props.row.original;
          return (
            <div className="flex items-center gap-2">
                {ipLocation && getLocationInfo(ipLocation)}
            </div>
          );
        },
      },
    ],
    []
  );

  const table = useReactTable({
    data: data ?? [],
    columns,
    // state: {
    //   sorting,
    //   globalFilter,
    // },
    // onSortingChange: setSorting,
    // getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: false,
  });

  const totalData = table.getFilteredRowModel().rows.length ?? data.length;

  const pageSizeOption = [
    { value: 10, label: "10 / page" },
    { value: 20, label: "20 / page" },
    { value: 30, label: "30 / page" },
    { value: 40, label: "40 / page" },
    { value: 50, label: "50 / page" },
  ];

  const onPaginationChange = (page) => {
    table.setPageIndex(page - 1);
  };

  const onSelectChange = (value) => {
    table.setPageSize(Number(value));
  };

  const getLocationInfo = (location) => {
    console.log("location", location);
    const { city = "", region = "", postal = "", latitude = "", longitute = "", country = "" } = location;
    console.log(`${city}, ${region}, ${postal}, ${latitude}, ${longitute}, ${country}`);
    if (city && region && postal) {
      return `${city}, ${region}, ${postal}`;
    }
    return "";
  };
  
  return (
    <Card className="mb-4 text-xs">
      <h5 className="mb-4">Scan history</h5>
      <Table className="border text-xs">
        <THead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </>
                    )}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </THead>
        <TBody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>;
                })}
              </Tr>
            );
          })}
        </TBody>
      </Table>
      {/* <RechargeConfirmation isOpen={openDialog} setOpen={setOpenDialog} onConfirm={onRecharge} /> */}
      <div className="flex items-center justify-between mt-4">
        <Pagination
          pageSize={table.getState().pagination.pageSize}
          // pageSize={table.getPrePaginationRowModel().rows.length}
          currentPage={table.getState().pagination.pageIndex + 1}
          total={totalData}
          onChange={onPaginationChange}
        />

        <div style={{ minWidth: 130 }}>
          <Select
            size="sm"
            isSearchable={false}
            value={pageSizeOption.filter((option) => option.value === table.getState().pagination.pageSize)}
            options={pageSizeOption}
            onChange={(option) => onSelectChange(option.value)}
          />
        </div>
      </div>
    </Card>
  );
};

export default ScanHistory;
